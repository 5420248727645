<template>
  <div class="modal" v-if="visible">
    <div class="modal-content" @keyup.esc="closeModal" tabindex="0">
      <span class="close" @click="closeModal">&times;</span>      
      <table>
        <tr>
          <td><label for="pomodoroTime">Pomodoro Time (minutes):</label></td>
          <td><input id="pomodoroTime" min="1" type="number" v-model.number="pomodoroTime" /></td>
        </tr>
        <tr>
          <td><label for="shortBreakTime">Short Break Time (minutes):</label></td>
          <td><input id="shortBreakTime" min="1" type="number" v-model.number="shortBreakTime" /></td>
        </tr>
        <tr>
          <td><label for="longBreakTime">Long Break Time (minutes):</label></td>
          <td><input id="longBreakTime" min="1" type="number" v-model.number="longBreakTime" /></td>
        </tr>
        <tr>
          <td><label for="pomodoroTurns">Long Break Frequency (pomodoros):</label></td>
          <td><input id="pomodoroTurns" min="1" type="number" v-model.number="pomodoroTurns" /></td>
        </tr>
        <tr>
          <td><label for="autoStartNextTask">Autostart next task:</label></td>
          <td><input id="autoStartNextTask" type="checkbox" v-model="autoStartNextTask" /></td>
        </tr>
        <tr>
          <td><label for="playSounds">Play Sounds:</label></td>
          <td><input id="playSounds" type="checkbox" v-model="playSounds" /></td>
        </tr>
        <tr>
          <td><label for="resetHistory">Clear History:</label></td>
          <td><button @click="confirmResetHistory" class="reset-history-button">Reset</button></td>
        </tr>
      </table>
      <div class="modal-buttons">
        <button @click="applySettings" class="modal-button">Apply</button>
        <button @click="revertChanges" class="modal-button">Cancel</button>
      </div>
    </div>
    <div class="confirmation-modal" v-if="showConfirmationModal">
      <div class="confirmation-content">
        <p>Are you sure you want to reset the history?</p>
        This will clear all saved data.
        <div class="confirmation-buttons">
          <button @click="resetHistory" class="confirmation-button yes-button">Yes</button>
          <button @click="cancelResetHistory" class="confirmation-button no-button">No</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /**
   * OptionsModal component
   * 
   * Props:
   * - visible: Boolean - Indicates if the modal is visible.
   * - settings: Object - The current settings for the pomodoro timer.
   * - test: Boolean - Indicates if the test mode is enabled.
   * 
   * Data:
   * - pomodoroTime: Number - The pomodoro time in minutes.
   * - shortBreakTime: Number - The short break time in minutes.
   * - longBreakTime: Number - The long break time in minutes.
   * - pomodoroTurns: Number - The number of pomodoro turns before a long break.
   * - playSounds: Boolean - Indicates if sounds should be played.
   * 
   * Methods:
   * - closeModal: Closes the modal.
   * - applySettings: Applies the updated settings.
   * - revertChanges: Reverts any current updates.
   */
  name: 'OptionsModal',
  props: {
    visible: Boolean,
    settings: Object,
    test: Boolean
  },
  data() {
    const savedSettings = JSON.parse(localStorage.getItem('pomodoroSettings')) || {};
    return {
      pomodoroTime: this.test ? 1 : (savedSettings.pomodoroTime || this.settings.pomodoroTime) / 60, // Convert to minutes
      shortBreakTime: this.test ? 1 : (savedSettings.shortBreakTime || this.settings.shortBreakTime) / 60, // Convert to minutes
      longBreakTime: (savedSettings.longBreakTime || this.settings.longBreakTime) / 60, // Convert to minutes
      pomodoroTurns: savedSettings.pomodoroTurns || this.settings.pomodoroTurns,
      playSounds: savedSettings.playSounds !== undefined ? savedSettings.playSounds : this.settings.playSounds,
      autoStartNextTask: savedSettings.autoStartNextTask !== undefined ? savedSettings.autoStartNextTask : false, // Default to false
      originalSettings: { ...this.settings }, // Store original settings
      showConfirmationModal: false
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    applySettings() {
      if (this.pomodoroTime < 1 || this.shortBreakTime < 1 || this.longBreakTime < 1 || this.pomodoroTurns < 1) {
        alert('Incorrect value');
        return;
      }
      const updatedSettings = {
        pomodoroTime: this.pomodoroTime * 60, // Convert back to seconds
        shortBreakTime: this.shortBreakTime * 60, // Convert back to seconds
        longBreakTime: this.longBreakTime * 60, // Convert back to seconds
        pomodoroTurns: this.pomodoroTurns,
        playSounds: this.playSounds,
        autoStartNextTask: this.autoStartNextTask
      };
      localStorage.setItem('pomodoroSettings', JSON.stringify(updatedSettings)); // Store settings in local storage
      this.$emit('update-settings', updatedSettings);
      this.closeModal(); // Ensure the modal is closed after applying settings
    },
    revertChanges() {
      // Revert changes to the original settings
      this.pomodoroTime = this.originalSettings.pomodoroTime / 60;
      this.shortBreakTime = this.originalSettings.shortBreakTime / 60;
      this.longBreakTime = this.originalSettings.longBreakTime / 60;
      this.pomodoroTurns = this.originalSettings.pomodoroTurns;
      this.playSounds = this.originalSettings.playSounds;
      this.autoStartNextTask = this.originalSettings.autoStartNextTask;
      this.closeModal();
    },
    handleEscapeKey(event) {
      if (event.key === 'Escape') {
        this.revertChanges();
      }
    },
    confirmResetHistory() {
      this.showConfirmationModal = true;
    },
    resetHistory() {
      localStorage.clear();
      this.$emit('reset-history');
      this.showConfirmationModal = false;
      this.revertChanges(); // Revert to default settings after clearing localStorage
      this.$emit('update-counter', 0); // Update the counter value to 0
      this.$emit('update-dialer', this.pomodoroTime); // Update the dialer to the initial pomodoro time
      this.$emit('update-estimated-duration'); // Update the estimated duration
    },
    cancelResetHistory() {
      this.showConfirmationModal = false;
    }
  },
  mounted() {
    window.addEventListener('keyup', this.handleEscapeKey);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.handleEscapeKey);
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        const savedSettings = JSON.parse(localStorage.getItem('pomodoroSettings')) || {};
        // Reset to original settings when modal is opened
        this.pomodoroTime = (savedSettings.pomodoroTime || this.originalSettings.pomodoroTime) / 60;
        this.shortBreakTime = (savedSettings.shortBreakTime || this.originalSettings.shortBreakTime) / 60;
        this.longBreakTime = (savedSettings.longBreakTime || this.originalSettings.longBreakTime) / 60;
        this.pomodoroTurns = savedSettings.pomodoroTurns || this.originalSettings.pomodoroTurns;
        this.playSounds = savedSettings.playSounds !== undefined ? savedSettings.playSounds : this.originalSettings.playSounds;
        this.autoStartNextTask = savedSettings.autoStartNextTask !== undefined ? savedSettings.autoStartNextTask : this.originalSettings.autoStartNextTask;
      }
    }
  }
};
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  cursor: pointer;
}
table {
  width: 100%;
  margin-bottom: 20px;
}
td {
  padding: 10px;
}
input[type="number"] {
  width: 80%; /* Adjusted width to make input boxes narrower */
  padding: 5px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}
input[type="number"]:focus {
  border-color: #007bff;
  outline: none;
}
input[type="checkbox"] {
  transform: scale(1.2);
}
.modal-buttons {
  display: flex;
  justify-content: space-between;
}
.modal-button {
  padding: 10px 20px;
  font-size: 1.2em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
.modal-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
.modal-button:active {
  background-color: #004494;
  transform: scale(1);
}
.reset-history-button {
  padding: 5px 10px;
  font-size: 1em;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
.reset-history-button:hover {
  background-color: #cc0000;
  transform: scale(1.05);
}
.reset-history-button:active {
  background-color: #990000;
  transform: scale(1);
}
.confirmation-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.confirmation-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.confirmation-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.confirmation-button {
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
.yes-button {
  background-color: #ff4d4d;
  color: white;
}
.yes-button:hover {
  background-color: #cc0000;
  transform: scale(1.05);
}
.yes-button:active {
  background-color: #990000;
  transform: scale(1);
}
.no-button {
  background-color: #007bff;
  color: white;
}
.no-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
.no-button:active {
  background-color: #004494;
  transform: scale(1);
}
@media (max-width: 600px) {
  .modal-content {
    width: 90%;
    padding: 10px;
  }
  .close {
    font-size: 1.2em;
  }
  td {
    padding: 5px;
  }
  .modal-button {
    padding: 5px 10px;
    font-size: 1em;
  }
}
</style>