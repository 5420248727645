import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'http://127.0.0.1:8000'; // Laravel app URL

createApp(App).mount('#app')

// Example of login request
// axios.get('/sanctum/csrf-cookie').then(() => {
//     axios.post('/login', {
//         email: 'cristi@atob.ro',
//         password: 'cristian'
//     }).then(response => {
//         console.log(response.data);
//     }).catch(error => {
//         console.error(error);
//     });
// });