<template>
    <div v-if="visible" class="modal">
        <div class="modal-content">
            <span class="close" @click="$emit('close')">&times;</span>
            <form @submit.prevent="login">
                <input v-model="email" type="email" placeholder="Email" required />
                <input v-model="password" type="password" placeholder="Password" required />
                <button type="submit">Login</button>
            </form>
        </div>
    </div>
    <div v-if="registerVisible" class="modal">
        <div class="modal-content">
            <span class="close" @click="$emit('closeRegister')">&times;</span>
            <form @submit.prevent="register">
                <input v-model="name" type="text" placeholder="Name" required />
                <input v-model="email" type="email" placeholder="Email" required />
                <input v-model="password" type="password" placeholder="Password" required />
                <input v-model="password_confirmation" type="password" placeholder="Confirm Password" required />
                <button type="submit">Register</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        registerVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            email: '',
            password: '',
            name: '',
            password_confirmation: ''
        };
    },
    methods: {
        login() {
            axios.post('/login', {
                email: this.email,
                password: this.password
            }).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.error(error);
            });
        },
        register() {
            axios.post('/register', {
                name: this.name,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation
            }).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.error(error);
            });
        }
    }
};
</script>

<style scoped>
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
    cursor: pointer;
}
input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px; /* Add margin between input fields */
}
button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #0964ecd7;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}
button:hover {
    background-color: #075bb5;
}
</style>