<template>
  <div class="timer">
    <svg class="dial" viewBox="0 0 100 100">
      <circle class="background" cx="50" cy="50" r="45"></circle>
      <circle class="progress" cx="50" cy="50" r="45" :stroke-dasharray="circumference" :stroke-dashoffset="progressOffset" :stroke="dialColor"></circle>
      <text x="50%" y="50%" text-anchor="middle" dy=".4em">{{ formattedTime }}</text>
    </svg>
    <div class="current-task">
      <label class="current-task-label">{{ currentTask }}</label>
    </div>
  </div>
</template>

<script>
export default {
  /**
   * PomodoroTimer component
   * 
   * Props:
   * - time: Number - The initial time for the timer in seconds.
   * - shortBreakTime: Number - The duration of the short break in seconds.
   * - longBreakTime: Number - The duration of the long break in seconds.
   * - pomodoroTurns: Number - The number of pomodoro turns before a long break.
   * - currentTurn: Number - The current turn in the pomodoro cycle.
   * 
   * Data:
   * - currentTime: Number - The current time left on the timer.
   * - totalTime: Number - The total time set for the timer.
   * - isRunning: Boolean - Indicates if the timer is currently running.
   * - interval: Number - The interval ID for the timer.
   * - currentTask: String - The current task being worked on.
   * - dialColor: String - The color of the progress dial.
   * 
   * Computed:
   * - formattedTime: String - The formatted time in MM:SS format.
   */
  name: 'PomodoroTimer',
  props: ['time', 'shortBreakTime', 'longBreakTime', 'pomodoroTurns', 'currentTurn'],
  data() {
    return {
      currentTime: this.time, // local copy of the time prop
      totalTime: this.time, // total time for the timer
      isRunning: false,
      interval: null,
      currentTask: '',
      dialColor: 'light-gray'
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.currentTime / 60);
      const seconds = this.currentTime % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    circumference() {
      return 2 * Math.PI * 45;
    },
    progressOffset() {
      return this.circumference - (this.currentTime / this.totalTime) * this.circumference; // Adjusted to make the counter go in the other direction
    }
  },
  methods: {
    startTimer() {
      if (!this.isRunning) {
        this.dialColor = 'blue';
        this.interval = setInterval(() => {
          if (this.currentTime > 0) {
            this.currentTime--;
          } else {
            clearInterval(this.interval);
            this.isRunning = false;
            this.$emit('complete-task');
            if (this.$parent.playSounds) {
              this.playStartBreakSound();
            }
          }
        }, 1000); // Ensure the interval is set to 1000 milliseconds (1 second)
        this.isRunning = true;
      }
    },
    startBreak(isLongBreak = false) {
      this.currentTask = isLongBreak ? 'Long Break' : 'Short Break';
      this.currentTime = isLongBreak ? this.longBreakTime : this.shortBreakTime;
      this.totalTime = this.currentTime;
      this.dialColor = 'green';
      this.interval = setInterval(() => {
        if (this.currentTime > 0) {
          this.currentTime--;
        } else {
          clearInterval(this.interval);
          this.dialColor = 'light-gray';
          this.isRunning = false;
          if (this.$parent.playSounds) {
            this.playEndBreakSound();
          }
          this.resetTimer(this.time); // Reset to Pomodoro Timer value
          this.$emit('break-complete'); // Emit event to indicate break completion
        }
      }, 1000); // Ensure the interval is set to 1000 milliseconds (1 second)
      this.isRunning = true;
    },
    resetToInitialState() {
      clearInterval(this.interval);
      this.currentTime = this.time;
      this.totalTime = this.time;
      this.isRunning = false;
      this.dialColor = 'light-gray';
    },
    pauseTimer() {
      clearInterval(this.interval);
      this.isRunning = false;
    },
    resumeTimer() {
      if (!this.isRunning) {
        this.startTimer();
      }
    },
    resetTimer(newTime) {
      clearInterval(this.interval);
      this.currentTime = newTime;
      this.totalTime = newTime;
      this.isRunning = false;
      this.dialColor = 'light-gray';
    },
    toggleTimer() {
      if (this.isRunning) {
        this.pauseTimer();
      } else {
        this.startTimer();
      }
    },
    updateCurrentTask(task) {
      this.currentTask = task;
    },
    playStartBreakSound() {
      const audio = new Audio('assets/intro-sound.mp3');
      audio.play();
    },
    playEndBreakSound() {
      const audio = new Audio('assets/notification-beep.mp3');
      audio.play();
    }
  }
};
</script>

<style scoped>
.timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 5em;
}
.dial {
  width: 250px;
  height: 250px;
}
.background {
  fill: none;
  stroke: #ddd;
  stroke-width: 8;
}
.progress {
  fill: none;
  stroke-width: 8;
  stroke-linecap: round;
  transform: rotate(-90deg); /* Reverted to start progress bar at -90 degrees */
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 1s linear;
}
text {
  font-size: 0.3em;
  fill: #333;
}
.current-task {
  margin-top: 20px;
  font-size: 0.3em;
}
.current-task-label {
  font-size: 1.2em;
  color: #333;
  font-weight: bold;
}
@media (max-width: 600px) {
  .timer {
    height: 200px;
    font-size: 3em;
  }
  .dial {
    width: 150px;
    height: 150px;
  }
  text {
    font-size: 0.6em;
    fill: #333;
  }
  .current-task {
    margin-top: 10px;
    font-size: 0.5em;
  }
  .current-task-label {
    font-size: 1.0em;
  }
}
</style>