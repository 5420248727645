<template>
  <nav class="navbar">
    <div class="logo"></div>
    <div class="nav-buttons">
      <div class="dropdown">
      <button class="nav-button dropdown-toggle" @click="toggleDropdown">
        <i class="fas fa-user"></i> Menu
      </button>
      <div v-if="dropdownVisible" class="dropdown-menu">
        <div class="dropdown-item" @click="showLogin">
        <i class="fas fa-sign-in-alt"></i> Login
        </div>
        <div class="dropdown-item" @click="showRegister">
        <i class="fas fa-user-plus"></i> Register
        </div>
      </div>
      </div>
    </div>
  </nav>
  <div id="projtimer">
    <PomodoroTimer ref="pomodoroTimer" :time="time" :shortBreakTime="shortBreakTime" :longBreakTime="longBreakTime" :pomodoroTurns="pomodoroTurns" :currentTurn="currentTurn" @complete-task="completeTask" @break-complete="breakComplete" />
    <PomodoroControls ref="pomodoroControls" @start="startTimer" @pause="pauseTimer" @resume="resumeTimer" @reset="resetTimer" @open-options="openOptions" @update-current-task="updateCurrentTask" @start-timer="startTimer" />
    <OptionsModal :visible="isOptionsModalVisible" :settings="settings" @close="closeOptions" @update-settings="updateSettings" @reset-history="resetHistory" />
    <AppAuthentication :visible="isLoginVisible" :registerVisible="isRegisterVisible" @close="hideLogin" @closeRegister="hideRegister" />
  </div>
</template>

<script>
import PomodoroTimer from './components/PomodoroTimer.vue';
import PomodoroControls from './components/PomodoroControls.vue';
import OptionsModal from './components/OptionsModal.vue';
import AppAuthentication from './components/AppAuthentication.vue';

export default {
  components: {
    PomodoroTimer,
    PomodoroControls,
    OptionsModal,
    AppAuthentication
  },
  data() {
    const savedSettings = JSON.parse(localStorage.getItem('pomodoroSettings')) || {};
    return {
      time: savedSettings.pomodoroTime || 25 * 60, // Initial time in seconds
      interval: null,
      pomodoroTime: savedSettings.pomodoroTime || 25 * 60, // Pomodoro time in seconds
      shortBreakTime: savedSettings.shortBreakTime || 5 * 60, // Short break time in seconds
      longBreakTime: savedSettings.longBreakTime || 15 * 60, // Long break time in seconds
      pomodoroTurns: savedSettings.pomodoroTurns || 4,
      currentTurn: 0,
      isOptionsModalVisible: false,
      playSounds: savedSettings.playSounds !== undefined ? savedSettings.playSounds : true,
      autoStartNextTask: savedSettings.autoStartNextTask !== undefined ? savedSettings.autoStartNextTask : false, // Default to false    
      dropdownVisible: false,
      isLoginVisible: false,
      isRegisterVisible: false
    };
  },
  computed: {
    settings() {
      return {
        pomodoroTime: this.pomodoroTime,
        shortBreakTime: this.shortBreakTime,
        longBreakTime: this.longBreakTime,
        pomodoroTurns: this.pomodoroTurns,
        playSounds: this.playSounds,
        autoStartNextTask: this.autoStartNextTask
      };
    },
    progressPercentage() {
      if (this.$refs.pomodoroTimer) {
        return ((this.$refs.pomodoroTimer.currentTime / this.$refs.pomodoroTimer.totalTime) * 100).toFixed(2);
      }
      return 0;
    },
    formattedTime() {
      if (this.$refs.pomodoroTimer) {
        const minutes = Math.floor(this.$refs.pomodoroTimer.currentTime / 60);
        const seconds = this.$refs.pomodoroTimer.currentTime % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
      return '';
    }
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    startTimer() {
      if (this.$refs.pomodoroControls.tasks.length > 0) {
        this.$refs.pomodoroTimer.startTimer();
      }
    },
    pauseTimer() {
      this.$refs.pomodoroTimer.pauseTimer();
    },
    resumeTimer() {
      this.$refs.pomodoroTimer.resumeTimer();
    },
    resetTimer() {
      this.$refs.pomodoroTimer.resetTimer(this.pomodoroTime);
      this.$refs.pomodoroControls.isRunning = false; // Ensure the timer is not running
      this.$refs.pomodoroControls.isPaused = false; // Ensure the timer is not paused
    },
    resetAll() {
      this.$refs.pomodoroTimer.resetToInitialState();
      this.$refs.pomodoroControls.resetAll();
    },
    openOptions() {
      this.isOptionsModalVisible = true;
    },
    closeOptions() {
      this.isOptionsModalVisible = false;
    },
    updateSettings(settings) {
      this.pomodoroTime = settings.pomodoroTime;
      this.shortBreakTime = settings.shortBreakTime;
      this.longBreakTime = settings.longBreakTime;
      this.pomodoroTurns = settings.pomodoroTurns;
      this.playSounds = settings.playSounds;
      this.autoStartNextTask = settings.autoStartNextTask;
      this.time = settings.pomodoroTime; // Update the time in the counter
      this.resetTimer(); // Reset the timer with the new settings
    },
    completeTask() {
      this.$refs.pomodoroControls.completeTask(0); // Pass the index of the first task
      this.currentTurn++;
      if (this.currentTurn % this.pomodoroTurns === (this.pomodoroTurns - 1)) {
        this.$refs.pomodoroTimer.startBreak(true); // Start long break after each series of Pomodoro Turns
        this.updateCurrentTask('Long Break'); // Update current task label to Long Break
      } else {
        this.$refs.pomodoroTimer.startBreak(false); // Start short break otherwise
        this.updateCurrentTask('Short Break'); // Update current task label to Short Break
      }
    },
    breakComplete() {
      this.resetTimer(this.pomodoroTime);
      if (this.$refs.pomodoroControls.tasks.length > 0) {
        this.updateCurrentTask(this.$refs.pomodoroControls.tasks[0].text);
        if (this.autoStartNextTask) {
          this.startTimer(); // Automatically start the next task if the toggle is enabled
        }
      } else {
        this.updateCurrentTask('No new items');
        this.$refs.pomodoroTimer.resetToInitialState(); // Reset to initial state
      }
    },
    updateCurrentTask(task) {
      this.$refs.pomodoroTimer.updateCurrentTask(task);
    },
    resetHistory() {
      this.currentTurn = 0; // Reset the counter value
      this.resetAll(); // Reset all components
      this.$refs.pomodoroTimer.resetTimer(this.pomodoroTime); // Reset the dialer to the initial pomodoro time
      this.$refs.pomodoroControls.tasks = []; // Clear all tasks
      this.$refs.pomodoroControls.completedTasks = []; // Clear all completed tasks
      this.$refs.pomodoroControls.updateCurrentTask(''); // Clear the current task
      this.$refs.pomodoroControls.tasksEstimatedTime = '0h 0m'; // Update the estimated duration
      window.location.reload();
    },
    showLogin() {
      this.isLoginVisible = true;
      this.dropdownVisible = false; // Close dropdown when showing login modal
    },
    hideLogin() {
      this.isLoginVisible = false;
    },
    showRegister() {
      this.isRegisterVisible = true;
      this.dropdownVisible = false; // Close dropdown when showing register modal
    },
    hideRegister() {
      this.isRegisterVisible = false;
    }
  }
};
</script>

<style>
#projtimer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}
.current-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
}
.progress {
  height: 100%;
  background-color: rgb(5, 168, 233);
  transition: width 1s linear;
}
.time-counter {
  font-size: 3em; /* Double the font size */
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: white;
  color: #0964ecd7;
}
.logo {
  font-size: 1.2em;
}
.nav-buttons {
  position: relative;
}
.nav-button {
  background: none;
  border: none;
  color: #0964ecd7;
  font-size: 1em;
  cursor: pointer;
}
.dropdown {
  position: relative;
}
.dropdown-toggle {
  background: none;
  border: none;
  color: #0964ecd7;
  font-size: 1em;
  cursor: pointer;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  color: #0964ecd7;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.dropdown-item {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #0964ecd7;
}
.dropdown-item:hover {
  background-color: #f0f0f0;
}
@media (max-width: 600px) {
  #projtimer {
    padding: 10px;
  }
  .current-time {
    gap: 5px;
    margin-top: 10px;
  }
  .progress-bar {
    height: 8px; /* Make the progress bar narrower for small screens */
  }
  .time-counter {
    font-size: 2.4em; /* Double the font size */
  }
}
</style>