<template>  
  <div>
    <div class="controls">
      <button @click="toggleTimer" class="control-button start-button">{{ buttonLabel }}</button>
      <button @click="$emit('reset')" class="control-button">Reset</button>
      <button @click="$emit('open-options')" class="control-button options-button">
        <i class="fas fa-cog"></i>
      </button>
    </div>
    <div class="todo-list">
      <h4 class="section-title">TODOs ({{ totalTodoCount }} tasks of {{ tasksEstimatedTime }})</h4>
      <div class="add-task">
        <input v-model="newTask" placeholder="Add a new task" @keyup.enter="addTask" class="add-task-input" />
        <button :class="{ disabled: newTask.trim() === '' }" @click="addTask" :disabled="newTask.trim() === ''" class="add-task-button">Add</button>
      </div>
      <ul>
        <li v-for="(task, index) in tasks" :key="index" draggable="true" @dragstart="dragStart(index)" @dragover.prevent @drop="drop(index)" class="todo-task">
          <span v-if="task.count > 1" class="task-count" @click="incrementTaskCount(index)">{{ task.count }}</span>
          <i v-else class="fas fa-tasks"></i> <!-- Replace counter with icon for single items -->
          <input v-model="task.text" @keyup.enter="saveTask(index)" class="draggable" />
          <div class="options">
            <button @click="toggleTodoDropdown(index)" class="actions-button">
              <i class="fas fa-ellipsis-v"></i>
            </button>
            <div v-if="task.showDropdown" class="dropdown-menu">
              <button v-if="task.count < 10" @click="incrementTaskCount(index)">
                <i class="fas fa-plus"></i> Add more
              </button>
              <button v-if="task.count > 1" @click="decrementTaskCount(index)">
                <i class="fas fa-minus"></i> Delete an item
              </button>              
              <button @click="completeTask(index)">
                <i class="fas fa-check"></i> Complete
              </button>
              <button @click="deleteTask(index)">
                <i class="fas fa-trash-alt"></i> Delete
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="completed-tasks">
      <h4 class="section-title">DONE tasks:</h4>
      <ul>
        <li v-for="(task, index) in completedTasks" :key="index" class="completed-task">
          <i class="fas fa-check-circle"></i>
          <span v-if="task.count > 1" class="task-count-done">{{ task.count }}</span> <!-- Display task count only if greater than 1 -->
          <input v-if="task.isEditing" v-model="task.text" @keyup.enter="saveCompletedTask(index)" @blur="saveCompletedTask(index)" />
          <span v-else>{{ task.text }}</span>
          <div class="options">
            <button @click="toggleDropdown(index)" class="actions-button">
              <i class="fas fa-ellipsis-v"></i>
            </button>
            <div v-if="task.showDropdown" class="dropdown-menu">
              <button @click="repeatTask(index)">
                <i class="fas fa-redo"></i> Repeat
              </button>
              <button @click="editCompletedTask(index)">
                <i class="fas fa-edit"></i> Edit
              </button>
              <button @click="deleteCompletedTask(index)">
                <i class="fas fa-trash-alt"></i> Delete
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="stats-today">
      <h4 class="section-title">Stats for today:</h4>
      <ul>
        <li>Completed tasks: {{ totalCompletedTasks }}</li>
        <li>Total time (with breaks): {{ completedTasksTimeWithBreaks }}</li>        
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PomodoroControls',
  data() {
    return {
      isRunning: false,
      isPaused: false,
      isShortBreak: false, // Define the isShortBreak property
      newTask: '',
      tasks: [], // Initialize tasks as an empty array
      completedTasks: [], // Initialize completedTasks as an array
      currentTurn: 0,
      draggedTaskIndex: null
    };
  },
  computed: {
    buttonLabel() {
      if (this.isRunning) {
        if (this.isPaused) {
          return this.isShortBreak ? 'Skip Break' : 'Resume';
        }
        return 'Pause';
      }
      if (this.isShortBreak) {
        return 'Skip Break';
      }
      return 'Start';
    },
    totalTodoCount() {
      return this.tasks.reduce((total, task) => total + task.count, 0);
    },
    tasksEstimatedTime() {
      const pomodoroTime = this.$root.pomodoroTime / 60; // Convert to minutes
      const shortBreakTime = this.$root.shortBreakTime / 60; // Convert to minutes
      const longBreakTime = this.$root.longBreakTime / 60; // Convert to minutes
      const pomodoroTurns = this.$root.pomodoroTurns;
      const tasksCount = this.totalTodoCount; // Use totalTodoCount

      // Full cycle time: All Pomodoros in a cycle + short breaks + one long break
      const fullCycleTime = (pomodoroTime * pomodoroTurns) + (shortBreakTime * (pomodoroTurns - 1)) + longBreakTime;

      // Number of full cycles
      const fullCycles = Math.floor(tasksCount / pomodoroTurns);

      // Remaining tasks after full cycles
      const remainingTasks = tasksCount % pomodoroTurns;

      // Time for remaining tasks: Pomodoros + all short breaks for remaining tasks
      const remainingTime =
        (pomodoroTime * remainingTasks) +
        (shortBreakTime * remainingTasks); // Include all short breaks for remaining tasks

      // Total time = Time for all full cycles + Time for remaining tasks
      const totalTime = (fullCycles * fullCycleTime) + remainingTime;

      //  Return the total time in hours and minutes
      return Math.floor(totalTime / 60) + 'h ' + (totalTime % 60) + 'm';
    },
    totalCompletedTasks() {
      return this.completedTasks.reduce((total, task) => total + task.count, 0);
    },
    completedTasksTimeWithBreaks() {
      const pomodoroTime = this.$root.pomodoroTime / 60; // Convert to minutes
      const shortBreakTime = this.$root.shortBreakTime / 60; // Convert to minutes
      const longBreakTime = this.$root.longBreakTime / 60; // Convert to minutes
      const pomodoroTurns = this.$root.pomodoroTurns;
      const tasksCount = this.completedTasks.reduce((total, task) => total + task.count, 0);

      const fullCycleTime = (pomodoroTime * pomodoroTurns) + (shortBreakTime * (pomodoroTurns - 1)) + longBreakTime;
      const fullCycles = Math.floor(tasksCount / pomodoroTurns);
      const remainingTasks = tasksCount % pomodoroTurns;
      const remainingTime = (pomodoroTime * remainingTasks) + (shortBreakTime * remainingTasks);

      const totalTime = (fullCycles * fullCycleTime) + remainingTime;
      return Math.floor(totalTime / 60) + 'h ' + (totalTime % 60) + 'm';
    }
  },
  methods: {
    toggleTimer() {
      if (this.isRunning) {
        if (this.isPaused) {
          this.isPaused = false;
          this.$emit('resume');
        } else {
          this.isPaused = true;
          this.$emit('pause');
        }
      } else {
        if (this.tasks.length > 0) {
          this.isRunning = true;
          this.isPaused = false;
          this.$emit('start');
          this.$emit('update-current-task', this.tasks[0].text);
          this.$emit('start-timer'); // Emit event to start the timer
        }
      }
    },
    addTask() {
      if (this.newTask.trim() !== '') {
        const taskText = this.newTask.trim();
        const existingTask = this.tasks.find(task => task.text === taskText);
        if (existingTask) {
          existingTask.count++;
        } else {
          this.tasks.unshift({ text: taskText, isEditing: false, id: Date.now(), count: 1, showSlider: false });
        }
        this.newTask = '';
        this.saveTasksToLocalStorage();
      }
    },
    saveTask(index) {
      this.tasks[index].isEditing = false;
      this.saveTasksToLocalStorage();
    },
        deleteTask(index) {
      if (this.tasks[index]) {
        this.tasks.splice(index, 1);
        this.saveTasksToLocalStorage();
        if (this.tasks.length === 0) {
          this.$emit('reset'); // Reset the counter when the last task is deleted
        }
      }
    },
    completeTask(index) {
      const completedTask = this.tasks[index];
      if (completedTask) {
        if (completedTask.count > 1) {
          completedTask.count--;
        } else {
          this.tasks.splice(index, 1);
        }
        const existingCompletedTask = this.completedTasks.find(task => task.text === completedTask.text);
        if (existingCompletedTask) {
          existingCompletedTask.count++;
        } else {
          this.completedTasks.push({ text: completedTask.text, isEditing: false, count: 1, showDropdown: false });
        }
        this.currentTurn++; // Increment turns counter when task is completed
        this.saveTasksToLocalStorage();
        this.saveCurrentTurnToLocalStorage();
        if (this.tasks.length === 0) {
          this.$emit('reset'); // Reset the counter when the last task is completed
        }
      }
    },
    saveTasksToLocalStorage() {
      localStorage.setItem('tasks', JSON.stringify(this.tasks));
      localStorage.setItem('completedTasks', JSON.stringify(this.completedTasks));
    },
    saveCurrentTurnToLocalStorage() {
      const today = new Date().toISOString().split('T')[0];
      localStorage.setItem('currentTurn', JSON.stringify({ date: today, turn: this.currentTurn }));
    },
    loadCurrentTurnFromLocalStorage() {
      const savedTurn = JSON.parse(localStorage.getItem('currentTurn'));
      const today = new Date().toISOString().split('T')[0];
      if (savedTurn && savedTurn.date === today) {
        this.currentTurn = savedTurn.turn;
      } else {
        this.currentTurn = 0; // Reset turns if it's a new day
      }
    },
    loadTasksFromLocalStorage() {
      const savedTasks = JSON.parse(localStorage.getItem('tasks')) || [];
      const savedCompletedTasks = JSON.parse(localStorage.getItem('completedTasks')) || [];
      this.tasks = savedTasks.length > 0 ? savedTasks : [{ text: 'Welcome task', isEditing: false, id: Date.now(), count: 1, showDropdown: false }];
      this.completedTasks = savedCompletedTasks;
    },
    dragStart(index) {
      this.draggedTaskIndex = index;
    },
    drop(index) {
      const draggedTask = this.tasks.splice(this.draggedTaskIndex, 1)[0];
      this.tasks.splice(index, 0, draggedTask);
      this.draggedTaskIndex = null;
      this.updateCurrentTask();
    },
    updateCurrentTask() {
      if (this.tasks.length > 0) {
        this.$emit('update-current-task', this.tasks[0].text);
      } else {
        this.$emit('update-current-task', '');
      }
    },
    resetAll() {
      this.isRunning = false;
      this.isPaused = false;
      this.$emit('reset-timer');
    },
    toggleDropdown(index) {
      this.completedTasks.forEach((task, i) => {
        if (i !== index) {
          task.showDropdown = false;
        }
      });
      this.completedTasks[index].showDropdown = !this.completedTasks[index].showDropdown;
      this.$forceUpdate(); // Force Vue to re-render the component
    },
    toggleTodoDropdown(index) {
      this.tasks.forEach((task, i) => {
        if (i !== index) {
          task.showDropdown = false;
        }
      });
      this.tasks[index].showDropdown = !this.tasks[index].showDropdown;
      this.$forceUpdate(); // Force Vue to re-render the component
    },
    closeTodoDropdowns() {
      this.tasks.forEach(task => {
        task.showDropdown = false;
      });
    },
    handleCompletedTaskAction(event, index) {
      const action = event.target.value;
      if (action === 'repeat') {
        this.repeatTask(index);
      } else if (action === 'edit') {
        this.editCompletedTask(index);
      } else if (action === 'delete') {
        this.deleteCompletedTask(index);
      }
      this.completedTasks[index].showDropdown = false; // Close the dropdown menu
      event.target.value = ''; // Reset the dropdown menu
    },
    closeDropdowns() {
      this.completedTasks.forEach(task => {
        task.showDropdown = false;
      });
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeTodoDropdowns();
        this.closeDropdowns();
      }
    },
    repeatTask(index) {
      const task = this.completedTasks[index];
      const existingTask = this.tasks.find(t => t.text === task.text);
      if (existingTask) {
        existingTask.count++;
      } else {
        this.tasks.unshift({ text: task.text, isEditing: false, count: 1 });
      }
      this.completedTasks[index].showDropdown = false; // Close the dropdown menu after repeating
      this.saveTasksToLocalStorage();
    },
    editCompletedTask(index) {
      this.completedTasks[index].isEditing = true;
      this.completedTasks[index].showDropdown = false; // Close the dropdown menu after editing
      this.$forceUpdate(); // Force Vue to re-render the component
    },
    deleteCompletedTask(index) {
      const completedTask = this.completedTasks[index];
      if (completedTask) {
        this.currentTurn -= completedTask.count; // Subtract the count from currentTurn
        this.completedTasks.splice(index, 1);
        this.saveTasksToLocalStorage();
        this.saveCurrentTurnToLocalStorage();
        this.$forceUpdate(); // Force Vue to re-render the component
      }
    },
    saveCompletedTask(index) {
      this.completedTasks[index].isEditing = false;
      this.completedTasks[index].text = this.completedTasks[index].text.trim(); // Update the task text
      this.saveTasksToLocalStorage();
      this.$forceUpdate(); // Force Vue to re-render the component
    },
    incrementTaskCount(index) {
      this.tasks[index].count++;
      this.saveTasksToLocalStorage();
    },
    decrementTaskCount(index) {
      if (this.tasks[index].count > 1) {
        this.tasks[index].count--;
        this.saveTasksToLocalStorage();
      }
    },
    toggleSlider(index) {
      this.tasks[index].showSlider = !this.tasks[index].showSlider;
    },
    hideSlider(index) {
      this.tasks[index].showSlider = false;
    }
  },
  mounted() {
    this.loadTasksFromLocalStorage();
    this.loadCurrentTurnFromLocalStorage();
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  watch: {
    tasks: {
      handler() {
        this.updateCurrentTask();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
}
button {
  padding: 10px 10px;
  font-size: 1.2em;
  text-align: center; /* Center the text */
}
button.disabled {
  background-color: lightgray;
  cursor: not-allowed;
}
.control-button {
  padding: 10px 20px;
  font-size: 1.2em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
.control-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
.control-button:active {
  background-color: #004494;
  transform: scale(1);
}
.start-button {
  width: 150px; 
}
.todo-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px; /* Ensure minimum width */
  padding: 5px 0; /* Add padding for better spacing */
}
.todo-dropdown-menu button {
  display: flex;
  align-items: center;
  gap: 10px; /* Add space between icon and text */
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}
.todo-dropdown-menu button:hover {
  background-color: #f0f0f0;
}
.todo-dropdown-menu i {
  font-size: 1.2em; /* Adjust icon size */
  color: #333; /* Icon color */
}
.todo-list, .completed-tasks {
  margin-top: 20px;
  width: 600px;
}
.add-task {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%; /* Match width with to-do tasks */
  /* flex-wrap: wrap; Allow input and button to wrap on smaller screens */
}
.add-task-input {
  flex: 1;
  padding: 10px;
  font-size: 1.2em;
  border: 2px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}
.add-task-input:focus {
  border-color: #007bff;
  outline: none;
}
.add-task-button {
  padding: 10px 20px;
  font-size: 1.2em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.add-task-button:hover {
  background-color: #0056b3;
}
.add-task-button.disabled {
  background-color: lightgray;
  cursor: not-allowed;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap; /* Allow tasks to wrap on smaller screens */
}
li input {
  flex: 1;
  padding: 10px;
  font-size: 1.2em;
}
.options {
  display: flex;
  gap: 10px;
  position: relative; /* Add relative positioning to the parent */
}
.options button {
  padding: 10px;
  font-size: 1em;
}
@media (max-width: 600px) {
  button {
    padding: 5px 10px;
    font-size: 1em;
    width: 80px;
  }
  .control-button {
    padding: 5px 10px;
    font-size: 1em;
    width: 80px; /* Adjust button width for mobile */
  }
  .add-task input {
    padding: 5px;
    font-size: 1em;
  }
  .add-task button {
    padding: 5px 10px;
    font-size: 1em;
  }
  li input {
    padding: 5px;
    font-size: 1em;
  }
  .options button {
    padding: 5px;
    font-size: 0.8em;
  }
}
.complete-button {
  background: none;
  border: none;
  color: green;
  cursor: pointer;
  font-size: 1em;
  width: 5px;
}
.complete-button:hover {
  color: darkgreen;
}
.delete-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1em;
  width: 5px;
}
.delete-button:hover {
  color: darkred;
}
.completed-task {
  display: flex;
  align-items: center;
  gap: 10px;
  color: green;
  font-size: 1.2em;
}
.completed-task i {
  color: green;
}
.completed-task .options {
  position: relative;
  margin-left: auto;
}
.actions-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #333;
}
.actions-button:hover {
  color: #007bff;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px; /* Ensure minimum width */
  padding: 5px 0; /* Add padding for better spacing */
}
.todo-dropdown {
  right: auto; /* Align dropdown next to the ellipsis icon */
  left: 0;
}
.dropdown-menu button {
  display: flex;
  align-items: center;
  gap: 10px; /* Add space between icon and text */
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}
.dropdown-menu button:hover {
  background-color: #f0f0f0;
}
.dropdown-menu i {
  font-size: 1.2em; /* Adjust icon size */
  color: #333; /* Icon color */
}
.completed-task input {
  flex: 1;
  padding: 5px;
  font-size: 1.2em;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}
.completed-task input:focus {
  border-color: #007bff;
  outline: none;
}
.todo-task {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%; /* Match width with add-task */
}
.todo-task i {
  color: #0964ecd7;
  margin-right: 10px; /* Add margin to align with task count */
  font-size: 1.2em; /* Ensure the icon size matches the task count size */
}
.todo-task input {
  flex: 1;
  padding: 10px;
  font-size: 1.2em;
  border: none;
  background: none;
}
.todo-task .options {
  display: flex;
  gap: 10px;
}
.todo-task .options button {
  padding: 10px;
  font-size: 1em;
}
.section-title {
  font-size: 1.3em;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}
.task-count {
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 1.0em; 
  margin-right: 10px;
}
.task-count-done {
  background-color: green;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 0.8em; 
  margin-right: 10px;
}
.draggable {
  width: 100%; 
  padding: 20px;
}
.stats-today {
  margin-top: 20px;  
}
.stats-today li {
  font-size: 1.2em;
}
@media (max-width: 600px) {
  .section-title {
  font-size: 1.2em;
  }
  .todo-list, .completed-tasks {
  margin-top: 10px;
  width: 100%;
  }
}
</style>